<template>
  <v-navigation-drawer v-model="openDrawer" fixed temporary class="nav-bar" @input="change">
    <v-list-item class="about-me-sec">
      <v-list-item-avatar>
        <v-icon color="white" x-large>
          account_circle
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="white--text">{{$i18n.locale == 'th' ? user.name_th || '' : user.name_en || ''}}</v-list-item-title>
        <v-list-item-subtitle class="white--text pt-1" style="font-size:12px;">Member Level: <span style="text-transform: uppercase;"> {{memberLevel}} </span> </v-list-item-subtitle>
        <v-list-item-subtitle class="white--text pt-1 d-flex align-center" style="font-size:14px;"><img class="img-jfin" :src="'/Image/jfin_2.png'"><span class="pl-2 pt-1" style="text-transform: uppercase;"> {{formatMoney(balance,7)}} JFIN</span> </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list>
      <v-list-item
        class="pl-5 pt-2"
        v-for="item in menus"
        :key="item.title"
        link
        @click="item.routeName != null ? $router.push(item.routeName) : openDrawer = !openDrawer"
      >
        <v-list-item-icon>
          <img :src="item.img" alt />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="title-menu">{{ $t(item.titleKey) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div class="tool-bar">
      <div class="social">
        <img src="/Image/menu/08_facebook.png" alt />
        <img src="/Image/menu/09_ig.png" alt />
        <img src="/Image/menu/10_tw.png" alt />
      </div>
      <div class="logout" @click="logout()">
        <img src="/Image/menu/11_logout.png" alt />
        <span class="white--text pt-1" style="font-size:13px;">{{$t('logout')}}</span>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    balance() {
      return this.$store.getters.balance;
    },
    user() {
      return this.$store.state.moduleAuth.eKYC;
    },
    memberLevel() {
      return this.$store.getters.memberLevel;
    }
  },
  data: () => ({
    openDrawer: false,
    menus: [
      {
        img: "/Image/menu/01_HOME.png",
        titleKey: "shopMenu",
        routeName: null,
      },
      // {
      //   img: "/Image/menu/02_NEWS.png",
      //   titleKey: "newsMenu",
      //   routeName: "/News",
      // },
      {
        img: "/Image/menu/04_MY_PURCHASES.png",
        titleKey: "purchasesMenu",
        routeName: "/Purchases",
      },
      {
        img: "/Image/menu/05_PURCHASE_HISTORY.png",
        titleKey: "purchaseHistoryMenu",
        routeName: "/History",
      },
      {
        img: "/Image/menu/07_SETTINGS.png",
        titleKey: "settingMenu",
        routeName: "/Setting",
      },
    ],
  }),
  watch: {
    drawer(val) {
      this.openDrawer = val;
    },
  },
  methods: {
    change(val) {
      this.$emit("Change", val);
    },
    logout(val) {
      this.$emit("Change", false);
      this.$emit("logout");
    },
  },
};
</script>

<style lang="scss">
.nav-bar {
  background: url("/Image/bg_menu.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 500px;
  .v-navigation-drawer__content {
    position: relative;
  }

  .about-me-sec {
    background-color: rgba(255, 255, 255, 0.15);
    height: calc(100vh * 0.11);
    min-height: 90px;
  }

  .title-menu {
    color: white;
    font-weight: 800;
    padding-top: 4px;
    font-size: 14px;
  }

  .tool-bar {
    position: absolute;
    bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    padding: 0px 10px 0px 5px;

    > div {
      display: flex;
      align-items: center;
    }

    .social {
      img {
        margin-right: 5px;
      }
    }

    .logout {
      span {
        font-size: 14px;
        font-weight: 800;
        line-height: 1;
      }
    }
  }

  .img-jfin {
    height: 20px;
    width: 20px;
  }
}
</style>