<template>
  <div class="home-page">
    <v-container class="layout-all-home" fluid>
      <v-row no-gutters class="layout-header-home" justify="center" align="center">
        <v-col cols="2" class="pl-2">
          <img src="/Image/sub_menu.png" @click="drawer = !drawer" />
        </v-col>
        <v-col cols="8">
          <div class="header">CASA LAPIN</div>
          <!-- <img class="img-jfin" src="/Image/jfin_2.png" alt="">
          {{balance}} -->
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-row v-if="!loadingState && coupons.length > 0" dense class="layout-body-home" align-content="start">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-for="(item,index) in coupons" :key="index+'item'">
          <v-card height="100%" color="#F5F5F5" outlined class="rounded-lg"
            @click="$router.push('/store/' + item.objectId)">
            <v-img class="white--text align-end" :src="item.image_url" height="200px" cover>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="#463729"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-title>
              <h5 style="line-height:1.3;">
                {{$i18n.locale == 'th' ? item.name || '' : item.name_en || ''}}
              </h5>
            </v-card-title>
            <v-card-subtitle class="text-title pb-1">
              {{$i18n.locale == 'th' ? item.title || '' : item.title_en  || ''}}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" class="layout-body-home" v-else-if="!loadingState && coupons.length == 0">
        <v-col cols="12" xs="12" sm="8" md="6" class="px-5">
          <v-card elevation="1" class="rounded-lg pa-5 text-center">
            {{$t('nodata')}}
          </v-card>
        </v-col>
      </v-row>
      <v-row class="layout-body-home" align-content="start" v-else>
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-for="x in 5" :key="x+'item'">
          <skeleton></skeleton>
        </v-col>
      </v-row>
    </v-container>
    <drawer :drawer="drawer" @Change="ChangeDrawer" @logout="logout"></drawer>
    <loading v-show="loadingState"></loading>
  </div>
</template>

<script>
  import Drawer from "../components/drawer";
  import Loading from "../components/loading";
  import skeleton from "../components/skeleton"
  export default {
    name: "Home",
    components: {
      drawer: Drawer,
      loading: Loading,
      skeleton
    },
    data: () => ({
      drawer: false,
      loadingState: true,
    }),
    computed: {
      coupons() {
        return this.$store.state.moduleStore.coupons;
      },
      eKYC() {
        return this.$store.state.moduleAuth.eKYC;
      },
      balance() {
        return this.$store.getters.balance
      }
    },
    methods: {
      ChangeDrawer(val) {
        this.drawer = val;
      },
      async logout() {
        this.loadingState = true;
        try {
          let config = await {
            headers: {
              "X-Parse-Application-Id": "928f24ed35d8876dee76d0a5460ef078",
              "content-type": "application/json",
              'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
            },
          };
          let response = await this.$axios.post(
            "/logout",
            "",
            config
          );
        } catch (err) {}
        await this.$store.commit('SET_LOGOUT');
        localStorage.removeItem('sessionToken_casalapin');
        this.$router.push("/PhoneVerify");
        this.loadingState = false;
      },
    },
    mounted() {
      this.$nextTick(() => {

      });
    },
    async beforeCreate() {
      await this.$store.dispatch("get_coupons");
      if (this.eKYC.name_th == '' && this.eKYC.name_en == '') {
        this.$router.push('/information');
      }
      await this.$store.dispatch("getBalance");
      this.loadingState = false;
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/color.scss";

  .home-page {
    height: 100%;

    .layout-all-home {
      height: 100%;
      padding: 0px;
      flex-flow: column;
      display: flex;

      .layout-header-home {
        position: fixed;
        z-index: 5;
        background: $base-color;
        min-width: 100%;
        height: 70px;
        background-image: url("/Image/BG_2.png");
        background-size: cover;
        background-position: center top;

        .header {
          color: white;
          font-size: 17px;
          font-weight: 800;
          text-align: center;
        }
        .img-jfin {
          width: 30px;
          height: 30px;
        }
      }

      .layout-body-home {
        padding: 80px 10px 10px 10px;
        background: url("/Image/BG_3.png");
        background-size: contain;
        width: 100%;
        margin: 0px !important;
        flex: 1 1 auto;

        .text-title {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          font-size: 12px;
        }
      }
    }
  }
</style>